.header-wrapper {

  background: var(--surface-d);

  .header {

    height: 4rem;

    background: rgba(var(--primary-color-rgb), 0.1);

    button.no-focus:enabled:focus {
      background: initial;
    }

    .p-button.p-button-icon-only.p-button-rounded {
      width: 3rem;
      height: 3rem;
      box-shadow: none;

      > span.pi {
        font-size: 1rem;
      }
    }

    > div.header-group {
      > :not(:last-child) {
        margin-right: 0.5rem;
      }

      > .p-avatar {
        border-radius: 50%;
      }
    }

    .max-w-t {
      transition: max-width 0.5s;
    }

  }

}
