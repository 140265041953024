.app-paginator {

  margin: 0 !important;

  .p-float-label > label {
    white-space: nowrap;
  }

  .p-inputnumber-input {
    text-align: center;
  }

}
