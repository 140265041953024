.main-section-wrapper {
  
  background: var(--surface-b);
  
  .main-section {
    width: 1200px;
    background: var(--surface-f);
    height: calc(100vh - 4rem);
    overflow: auto;
  }

}
