.input-field {
  .input-error {
    padding-right: var(--input-horizontal-padding);
    bottom: 2px;
  }

  .p-password {
    &.p-component.p-inputwrapper {
      flex-direction: column;
      transition: height 0.5s;
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
      // height without password strength panel
      height: calc(var(--input-font-size) + var(--input-vertical-padding) * 2 + 4px);
    }
    &>i {
      top: calc(var(--input-vertical-padding) + 2px);
      margin-top: initial;
    }
  }

  .input-field-wrapper input {
    width: 100%;
  }
}

.password-meter-hidden {
  transform: scaleY(0);
  opacity: 0;
  transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
}
