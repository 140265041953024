
@font-face {
  font-family: 'Chailce-Noggin';
  src: local('MyFont'), url(./resourses/Chailce-Noggin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.app-css-class {
  .p-float-label { // bootstrap float label adjustment
    input:focus ~ label, input:-webkit-autofill ~ label, input.p-filled ~ label, textarea:focus ~ label,
    textarea.p-filled ~ label, .p-inputwrapper-focus ~ label, .p-inputwrapper-filled ~ label,
    .p-tooltip-target-wrapper ~ label {
      top: -0.5rem;
    }
  }
}

.w-0 {
  width: 0;
}

button {
  box-shadow: none !important;
}

.transition-max-width {
  transition: max-width 0.5s;
}
